/*
 * Des:
 * version:
 * Author: yuelanfenghua
 * Date: 2022-08-15 09:32:36
 * LastEditors: yuelanfenghua
 * LastEditTime: 2023-12-11 18:58:23
 */

import { getStorageItem } from "@/cookie";
import { getUrlParam } from "@/util/index";
import { wechatConfig } from "@/util/config";
import { addSensorsTrack } from '@/util/sensors'

import { Toast, Dialog } from "vant";
import wx from "weixin-js-sdk";

//"channel": "1", // 1、掌上海银APP、2-财富小程序、3-健康小程序、4-海银基金、 5-财富CRM、 6-海银移动、

// 登陆
export function goLogin(_channel) {

  // if(process.env.NODE_ENV == "uat") return;

  const channel = _channel || getStorageItem("channelCode");
  const _deviceChannel = deviceChannel();
  const item = wechatConfig.filter(_ => _.code == channel)[0] || {};


  try {
    switch (channel) {
      case "1":
        const isiOS = getIsIOS();
        if (isiOS) {
          window.top.webkit.messageHandlers.goLogin.postMessage("");
        } else {
          window.top.android_app.goLogin();
        }
        setStatusBarStyle(_channel, "black");
        break;
      case "2": //财富小程序
      case "3": // 健康小程序
      case "4": //海银基金
        if (_deviceChannel == 2) {
          wx.miniProgram.reLaunch({
            // delta: 1
            url: item.loginPage,
          });
        }
        break;
      default:
    }
  } catch (error) {
    console.log("goLogin:", error);
  }
}

// 返回  离开h5
export function goBack(_channel) {

  const channel = _channel || getStorageItem("channelCode");
  const shares = getStorageItem("MEMBER_SHARE");
  const _deviceChannel = deviceChannel();
  try {
    switch (channel) {
      case "1":
        const isiOS = getIsIOS();
        if (isiOS) {
          window.top.webkit.messageHandlers.navigationBack.postMessage("");
        } else {
          window.top.android_app.pageClose();
        }
        setStatusBarStyle(_channel, "black");
        break;
      case "2": //财富小程序
      case "3": // 健康小程序
      case "4": //海银基金
        if (_deviceChannel == 2) {
          if (shares.share) {
            goPersonInfo();
            return;
          }
          wx.miniProgram.navigateBack({
            delta: 1,
          });
        }
        /*  if (_deviceChannel == 2) {
           if (shares.share) {
             goPersonInfo();
             return;
           }
           wx.miniProgram.navigateBack({
             delta: 1,
           });
         } */
        break;
      default:
        window.close();
        Toast("未知渠道:" + channel);
    }
  } catch (error) {
    console.log("goBack", error);
  }
}

// app 我的页面  jumpMinePage
// h5跳转新的h5贝面 jumpNewWebPage


export function goAppPage(routeType, data = {}) {
  const _params = getUrlParam(routeType);
  const keys = Object.keys(_params);
  const params = {};
  keys.map(k => {
    params[k] = data[_params[k]];
  })
  let sendMessage = JSON.stringify(params);
  const isiOS = getIsIOS();
  console.log(keys, _params);
  try {
    if (keys.length == 0) {
      if (isiOS) {
        window.top.webkit.messageHandlers[routeType].postMessage(sendMessage);
      } else {
        window.top.android_app[routeType]();
      }
    } else if (isiOS) {
      window.top.webkit.messageHandlers[routeType].postMessage(
        sendMessage
      );
    } else {
      window.top.android_app[routeType](sendMessage);
    }
  } catch (error) {
    console.log(routeType + ":", error, window.top.android_app[routeType]);
  }
}


// 跳转任务
export function goAppTask(_channel, data = {}, callTip) {
  const channel = _channel || getStorageItem("channelCode");
  const _deviceChannel = deviceChannel();
  const { linkUrl } = data;
  if (typeof callTip != 'function') {
    callTip = () => { };
  }

  try {
    switch (channel) {
      case "1":
        // 已完成
        if (data.status == '1') {
          return false;
        }
        if (data.jumpGrantType == '1' || !data.linkUrl) {
          callTip();
          return false;
        }

        if (!/^http/.test(linkUrl)) {
          goAppPage(linkUrl, data);
          return false;
        }
        const isiOS = getIsIOS();
        let params = {
          url: linkUrl, // ？？？
          show: false,
        };
        let sendMessage = JSON.stringify(params);
        if (isiOS) {
          window.top.webkit.messageHandlers.jumpNewWebPage.postMessage(
            sendMessage
          );
        } else {
          window.top.android_app.jumpNewWebPage(sendMessage);
        }
        break;

      case "2": //财富小程序
      case "3": // 健康小程序
        const item = wechatConfig.filter(_ => _.code == channel)[0] || {};
        if (_deviceChannel == 2) {
          wx.miniProgram.navigateTo({
            url: item.appDownPage,
          });
        } else {
          callTip();
        }
        break;
      case "4": //海银基金
        callTip();
        break;
      default:
        callTip();
        break;
    }
  } catch (error) {
    console.log("goAppTask:", error);
  }
}

// 个人信息页
export function goPersonInfo(_channel) {
  const channel = _channel || getStorageItem("channelCode");
  const _deviceChannel = deviceChannel();
  const item = wechatConfig.filter(_ => _.code == channel)[0] || {};
  try {
    switch (channel) {
      case "1":
        const isiOS = getIsIOS();
        let params = {
          url: process.env.VUE_APP_HY_PERSON_INFO, // ？？？
          // 'url': process.env.VUE_APP_SC_URL,
          title: "个人信息",
          show: false,
        };
        let sendMessage = JSON.stringify(params);
        if (isiOS) {
          // window.top.webkit.messageHandlers.goLogin.postMessage('');
          window.top.webkit.messageHandlers.jumpNewWebPage.postMessage(
            sendMessage
          );
        } else {
          // window.top.android_app.goLogin();
          window.top.android_app.jumpNewWebPage(sendMessage);
        }
        setStatusBarStyle(_channel, "black");
        break;
      case "2": //财富小程序
      case "3": // 健康小程序
      case "4": //海银基金
      case "8": //贵酒小程序
        if (_deviceChannel == 2) {
          wx.miniProgram.reLaunch({
            url: item.personPage,
          });
        }
        break;
      default:
    }
  } catch (error) {
    console.log("goPersonInfo:", error);
  }
}

// 龙腾权益页面
export function goLongTengEquity(_channel, url, callback) {
  const channel = _channel || getStorageItem("channelCode");
  const _deviceChannel = deviceChannel();
  try {
    switch (channel) {
      case "1":
        const isiOS = getIsIOS();
        let params = {
          url: encodeURI(url),
          title: "",
          show: true,
        };
        let sendMessage = JSON.stringify(params);
        console.log('app 跳转', sendMessage)
        if (isiOS) {
          // openFile
          window.top.webkit.messageHandlers.jumpNewWebPageNoToken.postMessage(
            sendMessage
          );
        } else {
          window.top.android_app.jumpNewWebPageNoToken(sendMessage);
        }
        if (typeof callback == 'function') {
          callback();
        }
        break;
      case "2": //财富小程序
      case "3": // 健康小程序
        if (_deviceChannel == 2) {
          window.location.href = url;
        }
        break;
      case "4": //海银基金
        break;
      default:
        window.location.href = url;
        break;
    }
  } catch (error) {
    console.log("goPersonInfo:", error);
  }
}

// 国学社区 hyw小课堂
export function goHYWCourse(_channel, callTip) {
  const url = process.env.VUE_APP_HY_COURSE;
  const channel = _channel || getStorageItem("channelCode");
  const _deviceChannel = deviceChannel();
  const item = wechatConfig.filter(_ => _.code == channel)[0] || {};
  try {
    switch (channel) {
      case "1":
        const isiOS = getIsIOS();
        let params = {
          url: encodeURI(url),
          title: "",
          show: false,
        };
        let sendMessage = JSON.stringify(params);
        if (isiOS) {
          window.top.webkit.messageHandlers.jumpNewWebPage.postMessage(
            sendMessage
          );
        } else {
          window.top.android_app.jumpNewWebPage(sendMessage);
        }
        break;
      case "2": //财富小程序
      case "3": // 健康小程序
        if (_deviceChannel == 2) {
          wx.miniProgram.navigateTo({
            url: item.appDownPage,
          });
        } else if (typeof callTip == 'function') {
          callTip();
        }
        break;
      case "4": //海银基金
        if (typeof callTip == 'function') {
          callTip();
        }
        break;
      default:
        window.location.href = url;
        break;
    }
  } catch (error) {
    console.log("goHYWCourse:", error);
  }
}
// 测试
export function goH5Test(_channel) {
  const channel = _channel || getStorageItem("channelCode");
  const _deviceChannel = deviceChannel();
  switch (channel) {
    case "1":
      const isiOS = getIsIOS();
      let params = {
        url: "https://testcm.hywintech.com/index1.html", // ？？？
        // 'url': process.env.VUE_APP_SC_URL,
        title: "个人信息11",
        show: true,
      };
      let sendMessage = JSON.stringify(params);
      if (isiOS) {
        // window.top.webkit.messageHandlers.goLogin.postMessage('');
        window.top.webkit.messageHandlers.jumpNewWebPage.postMessage(
          sendMessage
        );
      } else {
        // window.top.android_app.goLogin();
        window.top.android_app.jumpNewWebPage(sendMessage);
      }
      break;
    case "2": //财富小程序
    case "3": // 健康小程序
    case "4": //海银基金
      break;
    default:
  }
}

// 获取状态栏高度
export function getStatusHeight(_channel, callback = () => { }) {
  const channel = _channel || getStorageItem("channelCode");
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        const isiOS = getIsIOS();
        window.topOffset = (data) => {
          console.log("getStatusHeight", data);
          callback(JSON.parse(data));
        };
        const params = JSON.stringify({
          callback: "topOffset",
        });
        if (isiOS) {
          window.top.webkit.messageHandlers.getTopOffset.postMessage(params);
          // window.topOffset('{code:"00": height: "20"}')
        } else {
          window.top.android_app.getTopOffset(params);
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
        break;
      default:
        callback({ code: "00", height: 0 });
        break;
    }
  } catch (error) {
    console.log("getStatusHeight:", error);
  }
}
// 修改状态颜色
export function setStatusBarStyle(_channel, statusColor) {
  const channel = _channel || getStorageItem("channelCode");
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        const isiOS = getIsIOS();
        // barState : 状态 1 黑色， 2白色 默认黑色
        let barState = 1;
        if (statusColor == "white") barState = 2;
        const params = JSON.stringify({
          barState,
        });
        console.log(params);
        if (isiOS) {
          if (window.top.webkit.messageHandlers.changeStatusBarStyle)
            window.top.webkit.messageHandlers.changeStatusBarStyle.postMessage(
              params
            );
        } else {
          if (window.top.android_app.changeStatusBarStyle)
            window.top.android_app.changeStatusBarStyle(params);
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("setStatusBarStyle:", error);
  }
}
// 调用手机相册
export function hywAppImagePicker(_channel, callback) {
  const channel = _channel || getStorageItem("channelCode");
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        window.hywImagePicker = (data) => {
          callback(JSON.parse(data));
        };
        const isiOS = getIsIOS();
        const params = JSON.stringify({
          callback: "hywImagePicker",
        });
        if (isiOS) {
          window.top.webkit.messageHandlers.imagePicker.postMessage(params);
        } else {
          // window.top.android_app.imagePicker(params)
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("hywAppImagePicker:", error);
  }
}
// 开启相机权限
export function hywAppCameraPermission(_channel, callback) {
  const channel = _channel || getStorageItem("channelCode");
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        window.cameraPermission = (data) => {
          callback(JSON.parse(data));
        };
        const isiOS = getIsIOS();
        const params = JSON.stringify({
          callback: "cameraPermission",
        });
        if (isiOS) {
          // window.top.webkit.messageHandlers.cameraPermission.postMessage(params)
        } else {
          window.top.android_app.cameraPermission(params);
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("hywAppImagePicker:", error);
  }
}

// 跳转小程序
export function goOtherWeChat(
  _channel,
  data = {},
  callback,
) {

  const item = wechatConfig.filter(_ => _.appId == data.appId)[0] || {};
  let msg = '是否离开当前APP,前往小程序?'
  if (item.title) {
    msg = `是否离开当前APP,前往"${item.title}"小程序?`
  }
  const channel = _channel || getStorageItem("channelCode");
  data.path = (data.path || '').replace(/^\/*/, '/')
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        Dialog.confirm({
          title: "温馨提示",
          width: "29.5rem",
          className: "memberViewDialog",
          overlayClass: "memberOverlayDialog",
          message: msg,
        }).then(() => {
          data.path = data.path.replace(/jump=(1|2)\&?/, "").replace(/(\&|\?)$/, '');
          const isiOS = getIsIOS();
          const params = JSON.stringify({
            userName: data.userName,
            path: data.path,
            miniProgramType: data.miniProgramType,
          });
          console.log('wxLaunchMiniProgram', params)
          if (isiOS) {
            window.top.webkit.messageHandlers.wxLaunchMiniProgram.postMessage(
              params
            );
          } else {
            window.top.android_app.wxLaunchMiniProgram(params);
          }
          if (typeof callback == 'function') {
            callback();
          }
          addSensorsTrack({
            button_name: '确认',
            module_name: data.module_name,
            module_type: data.module_type,
          });
        }).catch((err) => {
          addSensorsTrack({
            button_name: '取消',
            module_name: data.module_name,
            module_type: data.module_type,
          });
          console.log(err);
        });

        break;
      case "2": // 财富小程序 跳转其他小程序
      case "3": // 健康小程序 跳转其他小程序
      case "4": // 海银基金小程序 跳转其他小程序
      case "8": // 贵酒小程序 跳转其他小程序

        console.log('wxLaunchMiniProgram', data)
        const item1 = wechatConfig.filter(_ => _.code == channel && data.appId == _.appId);
        if (item1.length > 0) { // 当前小程序
          const params = getUrlParam(data.path);
          switch (params.jump) {
            case "1":
              data.path = data.path.replace(/jump=1\&?/, "").replace(/(\&|\?)$/, '');
              wx.miniProgram.switchTab({
                url: data.path,
              });
              break;
            case "2":
              data.path = data.path.replace(/jump=2\&?/, "").replace(/(\&|\?)$/, '');
              wx.miniProgram.reLaunch({
                url: data.path,
              });
              break;
            default:
              wx.miniProgram.navigateTo({
                url: data.path,
              });

              if (typeof callback == 'function') {
                callback();
              }
              break;
          }
          return;
        }
        // 返回 退出web-view
        wx.miniProgram.navigateBack();
        // 因为只有执行了返回动作 小程序那边才能捕获到这边传递的参数 to: 'WeimobStore'
        wx.miniProgram.postMessage({
          data: {
            appId: data.appId,
            path: data.path,
          },
        });
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("wxLaunchMiniProgram:", error);
  }
}

// app 导航显示隐藏
export function setNavBarStatus(_channel, status = 2) {
  const channel = _channel || getStorageItem("channelCode");
  window.faceRecognitionShow = function (data) {
    console.log("faceRecognitionShow", data);
  };
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        const isiOS = getIsIOS();
        const params = JSON.stringify({
          navigationState: status,
          callback: "faceRecognitionShow",
        });
        console.log("params", params);
        if (isiOS) {
          window.top.webkit.messageHandlers.faceRecognitionShow.postMessage(
            params
          );
        } else {
          window.top.android_app.faceRecognitionShow(params);
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("setNavBarStatus:", error);
  }
}
// app 禁用返回  gesturetype  1 禁用 2 放开
export function setBangestures(_channel, gesturetype = 2) {
  const channel = _channel || getStorageItem("channelCode");

  try {
    switch (channel) {
      case "1": // 掌上海银APP
        const isiOS = getIsIOS();
        const params = JSON.stringify({
          gesturetype: gesturetype,
        });
        console.log("params", params);
        if (isiOS) {
          window.top.webkit.messageHandlers.bangestures.postMessage(
            params
          );
        } else {
          window.top.android_app.bangestures(params);
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("setBangestures:", error);
  }
}

// 下载保存图片
export function imageDownload(_channel, imgUrl) {
  const channel = _channel || getStorageItem("channelCode");
  window.downCallback = function (data) {
    if (data.code == "00") {
      Toast("保存成功");
    }
  };
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        const isiOS = getIsIOS();
        const params = JSON.stringify({
          imageUrl: imgUrl,
          callback: "downCallback",
        });
        if (isiOS) {
          window.top.webkit.messageHandlers.imageDownload.postMessage(params);
        } else {
          window.top.android_app.imageDownload(params);
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("setNavBarStatus:", error);
  }
}
// loading   load; // 加载动画  1，展示 0  隐藏
export function appShowLoading(_channel, load = 0) {
  const channel = _channel || getStorageItem("channelCode");
  try {
    switch (channel) {
      case "1": // 掌上海银APP
        const isiOS = getIsIOS();
        const params = JSON.stringify({
          load: load,
        });
        if (isiOS) {
          window.top.webkit.messageHandlers.showLoading.postMessage(params);
        } else {
          window.top.android_app.showLoading(params);
        }
        break;
      case "2": //财富小程序
        break;
      case "3": // 健康小程序
        break;
      case "4": //海银基金
      case "8": //贵酒小程序
        break;
      default:
        break;
    }
  } catch (error) {
    console.log("setNavBarStatus:", error);
  }
}

// 微信浏览器 1； 微信小程序 2； 3:ios；  4: Android； 5:其他
export function deviceChannel() {
  const ua = navigator.userAgent;
  const isWeixin = ua.indexOf("MicroMessenger") !== -1;
  if (isWeixin) {
    if (window.__wxjs_environment === "miniprogram") {
      return 2;
    } else {
      return 1;
    }
  } else if (ua.match(/(iphone|ipod|ipad);?/i)) {
    return 3;
  } else if (ua.match(/android|adr/i)) {
    return 4;
  }
  return 5;
}
// 是 ios
export function getIsIOS() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return isiOS;
}

export function getIOSVersion() {
  var str = navigator.userAgent.toLowerCase();
  try {
    var ver = str.match(/cpu iphone os (.*?) like mac os/);
    if (ver) {
      // console.log("你当前的Ios系统版本为：" + ver[1].replace(/_/g, "."));
      var version = parseFloat(ver[1].replace(/_/g, "."));
      return version;
    }
  } catch (e) {
    console.log("getIOSVersion", e);
    return -1;
  }

  return -1;
}
